import React from 'react';
import './Footer.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import {FaAngleUp} from "react-icons/fa";

const Footer: React.FC = () => {
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

  return (
      <section id='footer'>
          <h1>Be Kind To Your Buds</h1>
          {/* TODO */}
          {/*<Row className='join'>*/}
          {/*    <Col md={4}>*/}
          {/*        <h2>Join Newsletter</h2>*/}
          {/*    </Col>*/}
          {/*    <Col md={8} className='newsletter'>*/}
          {/*        <input type="text" />*/}
          {/*        <Button>Join</Button>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          <Row className='terms'>
              <Col md={4}>
                <a href='/terms'>Terms and Conditions</a>
              </Col>
              <Col md={4}></Col>
              <Col md={4}></Col>
          </Row>
          <footer>
            <p>Green Growers Club LLC © 2024 - All Rights Reserved.</p>
            <FaAngleUp title='go to top' onClick={() => {scrollTo('hero')}} />
          </footer>
      </section>
  );
};

export default Footer;
