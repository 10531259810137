import React from 'react';
import './ValueProposition.css';

import { FaJarWheat } from "react-icons/fa6";
import { GrCycle } from "react-icons/gr";
import Icon from '@mdi/react';
import { mdiCannabis } from '@mdi/js';
import { mdiAccountGroupOutline } from '@mdi/js';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ValueProposition: React.FC = () => {
    return (
        <section id='value-proposition'>
            <div className="pro-title">
                <h2>Grow Like A Pro</h2>
                <p>Green Growers Club helps you cultivate top-shelf buds and achieve consistent results to unlock the full potential of your grow ops.</p>
            </div>
            <div className="grid">
                <Row>
                    <Col md={6} className='value-proposition-item'>
                        <FaJarWheat size={200} />
                        <h3>Fat Yields</h3>
                        <p>Maximize your harvest and keep your stash jars full</p>
                    </Col>
                    <Col md={6} className={'value-proposition-item'}>
                        <Icon path={mdiCannabis} size={8.4} />
                        <h3>Tasty Buds</h3>
                        <p>Focus on terpene profiles to cultivate flavorful flowers</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className={'value-proposition-item'}>
                        <GrCycle size={200} />
                        <h3>Continuous Improvement</h3>
                        <p>Track, analyze, and refine SOPs for consistent results</p>
                    </Col>
                    <Col md={6} className={'value-proposition-item'}>
                        <Icon path={mdiAccountGroupOutline} size={8.4} />
                        <h3>Community Knowledge</h3>
                        <p>Connect with fellow growers and be kind to your buds</p>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default ValueProposition;
