import React, {useState, useEffect} from 'react';
import './KeyFeatures.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Icon from '@mdi/react';
import { mdiGreenhouse, mdiClipboardTextMultipleOutline, mdiToolboxOutline, mdiHandHeart } from '@mdi/js';
// Images for the features
import growOpFeat from '../assets/images/grow-op-feat.png';
import cultivarFeat from '../assets/images/cultivar-feat.png';
import cultivarsFeat from '../assets/images/cultivars-feat.png';
import sopsFeat from '../assets/images/sops-feat.png';
import gearFeat from '../assets/images/gear-feat.png';

const featImages = [
    growOpFeat,
    cultivarsFeat,
    cultivarFeat,
    sopsFeat,
    gearFeat
]

const KeyFeatures: React.FC = () => {
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    const isMobile = window.innerWidth <= 768;

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(currentIndex === featImages.length - 1) {
                setCurrentIndex(0);
            }
            else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 7000)

        return () => clearInterval(intervalId);
    }, [currentIndex])

    return (
        <section id="key-features">
            <h2>Cultivar Command Center</h2>
            <Row>
                <Col md={5} className='mock-img-container'>
                    <Image src={featImages[currentIndex]} className={'mock-img'} alt="Description"/>
                </Col>

                <Col md={7}>
                    <Row className={'key-feat-row'}>
                        <Col md={3} sm={3} xs={4}>
                            <Icon path={mdiGreenhouse}
                                  title="greenhouse"
                                  size={isMobile ? 4 : 6}
                            />
                        </Col>
                        <Col md={9} sm={9} xs={8}>
                            <p>Deep dive into your grow ops data for best growing conditions</p>
                        </Col>
                    </Row>

                    <Row className={'key-feat-row'}>
                        <Col md={3} sm={3} xs={4}>
                            <Icon path={mdiClipboardTextMultipleOutline}
                                  title="sops"
                                  size={isMobile ? 4 : 6}
                            />
                        </Col>
                        <Col md={9} sm={9} xs={8}>
                            <p>Manage or clone best-rated Standard Operating Procedures</p>
                        </Col>
                    </Row>

                    <Row className={'key-feat-row'}>
                        <Col md={3} sm={3} xs={4}>
                            <Icon path={mdiToolboxOutline}
                                  title="gear"
                                  size={isMobile ? 4 : 6}
                            />
                        </Col>
                        <Col md={9} sm={9} xs={8}>
                            <p>Discover gear and nutrients for every stage of your grow op</p>
                        </Col>
                    </Row>

                    <Row className={'key-feat-row'}>
                        <Col md={3} sm={3} xs={4}>
                            <Icon
                                path={mdiHandHeart}
                                title='community'
                                size={isMobile ? 4 : 6}
                            />
                        </Col>
                        <Col md={9} sm={9} xs={8}>
                            <p>Coming soon grow op stats, strain library and more!</p>
                            <Button onClick={() => {scrollTo('coming-soon')}}>See Timeline</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
)};

export default KeyFeatures;
