import React from 'react';
import './About.css';

const About: React.FC = () => {
  return (
    <section id="about">
      <h2>What is Green Growers Club?</h2>
      <p>
          Our mission is to provide a platform where cultivators can learn from each other,
          track their grow ops, and discover the best gear and nutrients for their cannabis cultivars.
      </p>
    </section>
  );
};

export default About;
